import api from '@/plugins/axios'

const base = 'v1/investidor/'
const baseDoc = 'v1/documento/investidor/'

export default {
  getEnums () {
    return api.get(`${base}enums`)
  },
  getBanks () {
    return api.get(`v1/bancos/aceitos-investidores`)
  },
  getApprovedBankAccount () {
    return api.get(`${base}conta-bancaria-aprovada`)
  },

  getProfile () {
    return api.get(base)
  },
  updateProfile (data) {
    return api.put(`${base}alterar-dados`, data)
  },

  getDocs () {
    return api.get(`${baseDoc}status`)
  },
  // viewDoc (docId) {
  //   return api.get(`${baseDoc}visualizar/${docId}`, { responseType: 'blob' })
  // },
  viewDoc (docId) {
    return api.get(`v1/documento/tomador/visualizar/investidor/${docId}`, { responseType: 'blob' })
  },
  putDoc (data, cb) {
    return api.put(`${baseDoc}upload`, data, {
      headers: {
        'Content-Type': 'multipart/form-data'
      },
      onUploadProgress: cb
    })
  },

  getAvatar () {
    return api.get(`${base}avatar`, { responseType: 'blob' })
  },

  putAvatar (data, cb) {
    return api.put(`${base}avatar`, data, {
      headers: {
        'Content-Type': 'multipart/form-data'
      },
      onUploadProgress: cb
    })
  }
}
